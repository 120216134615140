<template>
    <section class="repair">
        <div class="top-bar bg-white">
            <el-select v-model="formSearch.type" placeholder="请选择受理状态" size="small" style="margin-right: 10px">
              <el-option label="全部" value="-1"/>
              <el-option label="押金账单" value="0"/>
              <el-option label="水费余额" value="1"/>
              <el-option label="电费余额" value="2"/>
              <el-option label="押金余额" value="3"/>
              <el-option label="房租账单" value="4"/>
              <el-option label="杂费账单" value="5"/>
            </el-select>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getRepairTableData" :columns="tableColumn"
                   :query="formSearch" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
<!--            <el-table-column slot="toolbar" label="操作">-->
<!--                <template slot-scope="{ row }">-->
<!--                    <div class="table-tools">-->
<!--                        <span class="table-btn" @click="ckHandle(row)">查看附件</span>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </r-e-table>
        <r-e-dialog title="查看附件" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh">
            <div class="flex" style="height: 180px;overflow:scroll;">
                <div v-for="(item,index) in imgList" :key="index">
                    <div class="flex justify-center"
                         style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">
                        <el-image :src="item.url" :preview-src-list="[item.url]" class="flex align-center">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">加载中...</div>
                        </el-image>
                    </div>
                </div>
            </div>
        </r-e-dialog>
    </section>
</template>

<script>
import {transferRecordList} from "@/views/rental-management/tenant-management/data";
import {transferRecordListapi, repairInfo} from '@/api/tenant-management';

export default {
    name: "repair",
    props: {
        tenantData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            uuid: '',
            tableColumn: transferRecordList,
            formSearch: {
              type: '-1',
            },
            dialogVisible: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            active: 1,
            imgList: [],
        };
    },
    mounted() {

    },
    async created() {
        // 页面初始化
        // 获取用户uuid
        const {uuid} = this.tenantData;
        this.uuid = uuid;
    },
    methods: {
        // 报修管理列表
        getRepairTableData(params) {
            params.lessorUuid = this.uuid;
            return transferRecordListapi(params);
        },
        // 查询按钮
        handleSearch() {
            this.$refs['orderTableRef'].getTableData();
        },
        // 重置按钮
        handleReset() {
            this.formSearch.type = '-1'
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        // 查看附件
        ckHandle(data) {
            let that = this;
            const {repairGuid} = data.innerMap;
            const loadingOptions = that.loadingOptions;
            let loading = that.$loading({...loadingOptions});
            repairInfo({repairGuid}).then(res => {
                const {list} = res;
                that.imgList = list;
                that.dialogVisible = true;
            }).finally(() => loading.close());
        }
    }
}
</script>

<style lang="scss" scoped>
.repair {
    .top-bar {
        display: flex;
        // margin: VH(10px) 0 VH(25px);
        // padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            /*white-space: nowrap;*/
            text-align: center;
        }
    }
}</style>
